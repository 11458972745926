import { CampaignThemeStyles } from "@interfaces/theme/campaign/StylesJSON";
import { CampaignTextsJSONProps } from "@interfaces/theme/campaign/TextsJSON";

export const campaignThemeTexts: CampaignTextsJSONProps = {
  themeName: "campaign-theme",
  title: 'Campanha',
  header: {
    headerNav: {
      headerNavData: [
        {
          id: "1",
          title: "Como participar",
          link: "#como-participar",
          _id: "666b3e7562f23fa6e7f391a7"
        },
        {
          id: "2",
          title: "Regulamento",
          link: "#!",
          _id: "666b3e7562f23fa6e7f391a8"
        },
        {
          id: "3",
          title: "FAQ",
          link: "#duvidas-frequentes",
          _id: "666b3e7562f23fa6e7f391a9"
        },
        {
          id: "4",
          title: "Fale Conosco",
          link: "#fale-conosco",
          _id: "666b3e7562f23fa6e7f391aa"
        }
      ]
    },
    headerLogo: "/files/placeholder/logo.png",
    headerButtonCreateAccount: {
      headerButtonCreateAccountWithBorder: true,
      headerButtonCreateAccountText: "Criar conta",
      headerButtonCreateAccountLink: "",
    },
    headerButtonAccess: {
      headerButtonAccessWithBorder: true,
      headerButtonAccessText: "Acesse",
      headerButtonAccessLink: ""
    }
  },
  footer: {
    footerLogo: "/files/placeholder/logo.png",
    footerNav: [
      {
        id: "1",
        title: "Como participar",
        link: "#como-participar",
        _id: "666b3e7562f23fa6e7f391ab"
      },
      {
        id: "2",
        title: "Regulamento",
        link: "#!",
        _id: "666b3e7562f23fa6e7f391ac"
      },
      {
        id: "3",
        title: "FAQ",
        link: "#duvidas-frequentes",
        _id: "666b3e7562f23fa6e7f391ad"
      },
      {
        id: "4",
        title: "Fale Conosco",
        link: "#fale-conosco",
        _id: "666b3e7562f23fa6e7f391ae"
      }
    ]
  },
  heroSlider: [
    {
      id: "e51ce564",
      order: "1",
      src: "/files/placeholder/bannerDesktop.png",
      srcMobile: "/files/placeholder/bannerMobile.png",
      link: "",
      _id: "666b3e7562f23fa6e7f391af"
    }
  ],
  section1: {
    section1Visibility: "true",
    section1Headline: {
      section1HeadlineText: "Como participar"
    },
    section1ComponentData: [
      {
        section1ComponentDataId: "193dfba2",
        section1ComponentDataIconData: [
          "fal",
          "sign-in"
        ],
        section1ComponentDataTitle: "Cadastre-se",
        section1ComponentDataImage: '/files/theme/campaign/home/section1/icon1.png',
        section1ComponentDataSubtitle: "Para acumular pontos basta estar cadastrado no programa.",
        _id: "666b3e7562f23fa6e7f391b0"
      },
      {
        section1ComponentDataId: "193dfd64",
        section1ComponentDataIconData: [
          "far",
          "braille"
        ],
        section1ComponentDataTitle: "Ganhe Pontos",
        section1ComponentDataImage: '/files/theme/campaign/home/section1/icon2.png',
        section1ComponentDataSubtitle: "Ganhe 1 ponto a cada 1 real gasto em suas compras.",
        _id: "666b3e7562f23fa6e7f391b1"
      },
      {
        section1ComponentDataId: "193dfe68",
        section1ComponentDataIconData: [
          "far",
          "smile-beam"
        ],
        section1ComponentDataTitle: "Troque Seus Pontos",
        section1ComponentDataImage: '/files/theme/campaign/home/section1/icon3.png',
        section1ComponentDataSubtitle: "Aproveite as ofertas turbinadas que fazemos ao longo do ano.",
        _id: "666b3e7562f23fa6e7f391b2"
      }
    ]
  },
  section2: {
    section2_box1: {
      section2Box1Visibility: "true",
      section2Box1Title: "Ainda não é Cadastrado? ",
      section2Box1Text: "Para acumular pontos basta estar cadastrado no programa. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      section2Box1Image: "/files/placeholder/box1Image.png",
      section2Box1Button: {
        section2Box1ButtonLink: "#!",
        section2Box1ButtonText: "Cadastre-se agora",
        section2Box1ButtonWithBorder: false
      }
    },
    section2_box2: {
      section2_box2_visibility: "true",
      section2_box2_title: "Já é Cadastrado? ",
      section2_box2_text: "Para acumular pontos basta estar cadastrado no programa. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      section2_box2_image: "/files/placeholder/box2Image.png",
      section2_box2_button: {
        section2_box2_button_link: "#!",
        section2_box2_button_text: "Ir para o portal",
        section2_box2_button_withBorder: false
      }
    }
  },
  section3: {
    section3_headline: {
      section3HeadlineText: "Conheça nossos parceiros"
    },
    section3_styles: {
      section3StylesSlider: {
        section3StylesSliderArrowColor: "#757575"
      }
    },
    section3_sectionSlider: [
      {
        section3SectionSliderId: "5388f028",
        section3SectionSliderImage: "/files/placeholder-640x410.png",
        section3SectionSliderTitle: "Campanha 1",
        section3SectionSliderDescription: "Lorem, ipsum dolor sit amet consectetur adipisicing elit.",
        _id: "666b3e7562f23fa6e7f391b3"
      },
      {
        section3SectionSliderId: "5388f029",
        section3SectionSliderImage: "/files/placeholder-640x410.png",
        section3SectionSliderTitle: "Campanha 2",
        section3SectionSliderDescription: "Lorem, ipsum dolor sit amet consectetur adipisicing elit.",
        _id: "666b3e7562f23fa6e7f391b4"
      },
      {
        section3SectionSliderId: "5388f030",
        section3SectionSliderImage: "/files/placeholder-640x410.png",
        section3SectionSliderTitle: "Campanha 3",
        section3SectionSliderDescription: "Lorem, ipsum dolor sit amet consectetur adipisicing elit.",
        _id: "666b3e7562f23fa6e7f391b5"
      },
      {
        section3SectionSliderId: "5388f031",
        section3SectionSliderImage: "/files/placeholder-640x410.png",
        section3SectionSliderTitle: "Campanha 4",
        section3SectionSliderDescription: "Lorem, ipsum dolor sit amet consectetur adipisicing elit.",
        _id: "666b3e7562f23fa6e7f391b6"
      }
    ]
  },
  faq: {
    faqTitle: {
      faqTitleText: "FAQ"
    },
    faqContent: [
      {
        id: "01b3cd8a",
        title: "Sobre a promoção",
        questions: [
          {
            id: "f796db42",
            title: "O Que É Essa Promoção E Quem Pode Participar?",
            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec posuere porttitor dignissim. Suspendisse potenti. Suspendisse rutrum lacus ut nunc dignissim cursus. Etiam varius purus nec molestie facilisis. Maecenas a elementum tortor. Suspendisse potenti. Mauris rhoncus tincidunt massa et tincidunt.",
            _id: "666b3e7562f23fa6e7f391b8"
          },
          {
            id: "8b945e30",
            title: "Quem Não Pode Participar Da Promoção?",
            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec posuere porttitor dignissim. Suspendisse potenti. Suspendisse rutrum lacus ut nunc dignissim cursus. Etiam varius purus nec molestie facilisis. Maecenas a elementum tortor. Suspendisse potenti. Mauris rhoncus tincidunt massa et tincidunt.",
            _id: "666b3e7562f23fa6e7f391b9"
          },
          {
            id: "0ac19d88",
            title: "Como Vou Saber Se A Promoção É Segura?",
            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec posuere porttitor dignissim. Suspendisse potenti. Suspendisse rutrum lacus ut nunc dignissim cursus. Etiam varius purus nec molestie facilisis. Maecenas a elementum tortor. Suspendisse potenti. Mauris rhoncus tincidunt massa et tincidunt.",
            _id: "666b3e7562f23fa6e7f391ba"
          }
        ],
        _id: "666b3e7562f23fa6e7f391b7"
      },
      {
        id: "cab0bdeb",
        title: "Como participar",
        questions: [
          {
            id: "674c5a7c",
            title: "Como faço para participar da promoção?",
            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec posuere porttitor dignissim. Suspendisse potenti. Suspendisse rutrum lacus ut nunc dignissim cursus. Etiam varius purus nec molestie facilisis. Maecenas a elementum tortor. Suspendisse potenti. Mauris rhoncus tincidunt massa et tincidunt.",
            _id: "666b3e7562f23fa6e7f391bc"
          },
          {
            id: "8b941e30",
            title: "Como realizar o cadastro?",
            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec posuere porttitor dignissim. Suspendisse potenti. Suspendisse rutrum lacus ut nunc dignissim cursus. Etiam varius purus nec molestie facilisis. Maecenas a elementum tortor. Suspendisse potenti. Mauris rhoncus tincidunt massa et tincidunt.",
            _id: "666b3e7562f23fa6e7f391bd"
          },
          {
            id: "0ac16d88",
            title: "Quem são os participantes?",
            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec posuere porttitor dignissim. Suspendisse potenti. Suspendisse rutrum lacus ut nunc dignissim cursus. Etiam varius purus nec molestie facilisis. Maecenas a elementum tortor. Suspendisse potenti. Mauris rhoncus tincidunt massa et tincidunt.",
            _id: "666b3e7562f23fa6e7f391be"
          }
        ],
        _id: "666b3e7562f23fa6e7f391bb"
      },
      {
        id: "9f15b938",
        title: "Sobre as premiações",
        questions: [
          {
            id: "4997eoe9",
            title: "Quais são os prêmios?",
            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec posuere porttitor dignissim. Suspendisse potenti. Suspendisse rutrum lacus ut nunc dignissim cursus. Etiam varius purus nec molestie facilisis. Maecenas a elementum tortor. Suspendisse potenti. Mauris rhoncus tincidunt massa et tincidunt.",
            _id: "666b3e7562f23fa6e7f391c0"
          },
          {
            id: "8b945i30",
            title: "Como saber quem são os ganhadores?",
            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec posuere porttitor dignissim. Suspendisse potenti. Suspendisse rutrum lacus ut nunc dignissim cursus. Etiam varius purus nec molestie facilisis. Maecenas a elementum tortor. Suspendisse potenti. Mauris rhoncus tincidunt massa et tincidunt.",
            _id: "666b3e7562f23fa6e7f391c1"
          },
          {
            id: "0ac19r88",
            title: "O que preciso fazer para receber a premiação?",
            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec posuere porttitor dignissim. Suspendisse potenti. Suspendisse rutrum lacus ut nunc dignissim cursus. Etiam varius purus nec molestie facilisis. Maecenas a elementum tortor. Suspendisse potenti. Mauris rhoncus tincidunt massa et tincidunt.",
            _id: "666b3e7562f23fa6e7f391c2"
          }
        ],
        _id: "666b3e7562f23fa6e7f391bf"
      }
    ]
  }
}

export const campaignThemeStyles: CampaignThemeStyles = {
  headerCampaign: {
    bgColor: "#F1F1F1",
    navLinkColor: "#000000",
    navLinkActiveColor: "#CCCCCC",
    buttonAccessBorderColor: "#737D86",
    buttonAccessColor: "#737D86",
    buttonAuthBorderColor: "#737D86",
    buttonAuthColor: "#737D86",
  },
  howToParticipate: {
    titleColor: "#000000",
    campos: {
      backgroundColor: "#F1F1F1",
      textColor: "#000000",
      iconBackgroundColor: "#737D86",
      iconColor: "#737D86",
    },
  },
  notRegisteredYet: {
    titleColor: "#000000",
    textColor: "#000000",
    buttonBgColor: "#737D86",
    buttonColor: "#737D86",
  },
  alreadyRegistered: {
    titleColor: "#000000",
    textColor: "#000000",
    buttonBgColor: "#737D86",
    buttonColor: "#737D86",
  },
  meetPartners: {
    titleColor: "#000000",
    sliderArrowsColor: "#737D86",
  },
  faq: {
    titleColor: "#000000",
    toggleIconColor: "#737D86",
  },
  contact: {
    bgColor: "#F1F1F1",
    buttonBgColor: "#737D86",
    buttonColor: "#737D86",
  },
  footer: {
    footerLogo: "/files/logo.png",
    footerBackgroundColor: "#F1F1F1",
    footerColor: "#000000"
  },
}