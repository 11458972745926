import { BrowserRouter } from 'react-router-dom';
import Router from './Routes';

// Não vamos mais usar
// import { ThemeProvider } from '@hooks';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-loading-skeleton/dist/skeleton.css'
import { AuthProvider } from './contexts/auth/provider';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { location } from '@utils';
import { ThemingProvider } from './contexts/theme/provider';
import { CampaignProvider } from './contexts/campaign/provider';

function App() {
  const program = location.getFirstPathname() as string;

  const basename = (programString: string = program) => {
    if (!programString) {
      return '';
    }

    const splitId = programString.split('#')
    const programName = splitId[0]

    if (programName.indexOf('/') !== -1) {
      return programName
    } else {
      return `${programName}/`;
    }
  }
  return (
    <ThemingProvider>
      <CampaignProvider>
        <BrowserRouter basename={basename(program)}>
          <AuthProvider>
            <Router />
            <ToastContainer />
          </AuthProvider>
        </BrowserRouter>
      </CampaignProvider>
    </ThemingProvider>
  );
}

export default App;
